import React from 'react'
import Typography from '@material-ui/core/Typography'

type SubmittedFormProps = {
  message: string
  downloadUrl: string
  downloadText: string
  filename: string
}

export const SubmittedForm = ({
  message,
  downloadUrl,
  downloadText,
  filename,
}: SubmittedFormProps) => {
  return (
    <>
      <Typography className="formSubhead" component="p" style={{ maxWidth: 'unset' }} variant="h3">
        {message}
      </Typography>
      {downloadUrl ? (
        <Typography
          className="formSubhead"
          component="p"
          style={{ maxWidth: 'unset' }}
          variant="body1"
        >
          <br />
          <a
            download={filename}
            href={downloadUrl}
            rel="noopener noreferrer"
            className="button"
            target="_blank"
            title="Download or Open in New Window"
          >
            {downloadText}
          </a>
        </Typography>
      ) : null}
    </>
  )
}

export const DownloadNotFound = () => (
  <Typography className="formSubhead" component="p" variant="h3">
    Download file not found.
  </Typography>
)

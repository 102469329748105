import React from 'react'
import Typography from '@material-ui/core/Typography'

type HeaderProps = {
  headline: string
}

export const Header = ({ headline }: HeaderProps) => {
  return (
    <Typography className="formSubhead" component="h3" variant="h3">
      {headline}
    </Typography>
  )
}

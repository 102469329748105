import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const DownloadableResourceSection = styled.section`
  padding: 24px 0 0;

  .twoUp {
    margin-bottom: 96px;
    gap: 56px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-bottom: 0;
      gap: 32px;
    }
  }

  .headline {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 32px;
  }

  .formContainer {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  .whatsInsideContainer {
    width: calc(50% - 56px);
    max-width: 560px;
    min-width: 240px;
    flex-shrink: 1;

    @media (max-width: 1024px) {
      width: 100%;
      max-width: unset;
    }

    h3 {
      color: ${rspTheme.palette.primary.main};
      font-size: ${rspTheme.typography.h3.fontSize};
      font-weight: ${rspTheme.typography.h3.fontWeight};
      line-height: ${rspTheme.typography.h3.lineHeight};
      letter-spacing: ${rspTheme.typography.h3.letterSpacing};
      margin: 40px 0 16px;

      @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
        margin: 24px 0 36px;
      }
    }

    p,
    ul,
    ol {
      color: ${rspTheme.palette.text.primary};
      font-size: ${rspTheme.typography.body1.fontSize};
      font-weight: ${rspTheme.typography.body1.fontWeight};
      line-height: ${rspTheme.typography.body1.lineHeight};
      letter-spacing: ${rspTheme.typography.body1.letterSpacing};
      margin-bottom: 16px;

      @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
        line-height: 1.9;
      }
    }

    ul,
    ol {
      margin-block-start: 0;
      padding-inline-start: 16px;
    }

    ul {
      list-style: none;

      li {
        position: relative;
        margin-bottom: 8px;

        &::before {
          content: '•';
          position: absolute;
          color: ${rspTheme.palette.primary.light};
          font-size: 2rem;
          font-weight: 400;
          left: -16px;
          line-height: 1.5;
        }
      }
    }
  }

  > a {
    color: inherit;
    transition: all 0.2s ease-in-out;

    &:focus,
    &:hover {
      color: ${rspTheme.palette.primary.main};
    }
  }

  strong {
    font-weight: 400;
    font-size: inherit;
  }
`

export const PromoCardWrapper = styled.aside`
  background-color: ${rspTheme.palette.primary.background};
`

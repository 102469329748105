import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import { cleanString } from '../../utils/helpers'
import { InputProps } from './types'

type Extras = {
  prefilled: boolean
  reset: () => void
}
type EmailProps = InputProps & Extras

export const BusinessEmailInput = React.forwardRef(
  ({ isLoading, formContent, form, onBlur, onFocus, prefilled, reset }: EmailProps, ref) => {
    const [isClient, setClient] = React.useState(false)
    const key = isClient ? 'client' : 'server'
    const iProps = prefilled
      ? {
          endAdornment: <ClearIcon className="clearEmailIcon" fontSize="medium" id="clearEmailIcon" onClick={reset} />,
          readOnly: true,
        }
      : {}
    const classes = ['textField', prefilled && 'customBackgroundInput']
    React.useEffect(() => {
      setClient(true)
    }, [])
    return (
      <TextField
        key={key}
        InputProps={iProps}
        className={classes.join(' ')}
        disabled={isLoading}
        error={Boolean(form.touched.businessEmail ? form.errors.businessEmail : null)}
        helperText={form.touched.businessEmail ? form.errors.businessEmail : null}
        id="businessEmail"
        inputRef={ref}
        label={cleanString(formContent.business_email_label?.[0]?.copy)}
        name="businessEmail"
        onBlur={onBlur}
        onChange={form.handleChange}
        onFocus={onFocus}
        value={form.values.businessEmail}
        variant="filled"
        autoFocus
      />
    )
  },
)

export const FirstNameInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.firstName ? form.errors.firstName : null)}
    helperText={form.touched.firstName ? form.errors.firstName : null}
    id="firstName"
    label={cleanString(formContent.first_name_label?.[0]?.copy)}
    name="firstName"
    onChange={form.handleChange}
    value={form.values.firstName}
    variant="filled"
  />
)

export const LastNameInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.lastName ? form.errors.lastName : null)}
    helperText={form.touched.lastName ? form.errors.lastName : null}
    id="lastName"
    label={cleanString(formContent.last_name_label?.[0]?.copy)}
    name="lastName"
    onChange={form.handleChange}
    value={form.values.lastName}
    variant="filled"
  />
)

export const JobTitleInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.jobTitle ? form.errors.jobTitle : null)}
    helperText={form.touched.jobTitle ? form.errors.jobTitle : null}
    id="jobTitle"
    label={cleanString(formContent.job_title_label?.[0]?.copy)}
    name="jobTitle"
    onChange={form.handleChange}
    value={form.values.jobTitle}
    variant="filled"
  />
)

export const CountryInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.country ? form.errors.country : null)}
    helperText={form.touched.country ? form.errors.country : null}
    id="country"
    label={cleanString(formContent.countries_label?.[0]?.copy)}
    name="country"
    onChange={form.handleChange}
    select
    value={form.values.country}
    variant="filled"
  >
    {formContent.countries?.[0]?.reference?.map((country: any) => (
      <MenuItem key={country.id} value={cleanString(country.copy)}>
        {cleanString(country.copy)}
      </MenuItem>
    ))}
  </TextField>
)

export const CompanyNameInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.companyName ? form.errors.companyName : null)}
    helperText={form.touched.companyName ? form.errors.companyName : null}
    id="companyName"
    label={cleanString(formContent.company_name_label?.[0]?.copy)}
    name="companyName"
    onChange={form.handleChange}
    value={form.values.companyName}
    variant="filled"
  />
)

export const PhoneNumberInput = ({ isLoading, formContent, form }: InputProps) => (
  <TextField
    className="textField textFieldHalf"
    disabled={isLoading}
    error={Boolean(form.touched.phoneNumber ? form.errors.phoneNumber : null)}
    helperText={form.touched.phoneNumber ? form.errors.phoneNumber : null}
    id="phoneNumber"
    label={cleanString(formContent.phone_number_label?.[0]?.copy)}
    name="phoneNumber"
    onChange={form.handleChange}
    value={form.values.phoneNumber}
    variant="filled"
  />
)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useStore } from '@nanostores/react'

import {
  BusinessEmailInput,
  CompanyNameInput,
  CountryInput,
  FirstNameInput,
  JobTitleInput,
  LastNameInput,
} from './fields'
import { Header as FormHeader } from './header'
import { cleanString } from '../../utils/helpers'
import { FormLoader } from './styles'
import { userFormExpanded, storedUser, clearStoredUser, prefilledEmail } from './store'

export const FormState = ({ inputProps }) => {
  const user = useStore(storedUser)
  const formExpanded = useStore(userFormExpanded)
  const prefilled = prefilledEmail.get()
  // console.log({ prefilled, inputProps })
  const headline = prefilled
    ? `Hi, welcome back ${user.firstName}`
    : cleanString(inputProps.formContent.headline)
  React.useEffect(() => {
    inputProps.form.setValues(user)
  }, [user])

  return (
    <>
      <FormHeader headline={headline} />
      {formExpanded ? (
        <AllFields inputProps={inputProps} />
      ) : (
        <EmailOnly inputProps={inputProps} prefilled={prefilled} />
      )}
    </>
  )
}

const FormRow = ({ children }: { children: React.ReactNode }) => (
  <Grid alignItems="flex-start" container justifyContent="space-between">
    {children}
  </Grid>
)

export const EmailOnly = ({ inputProps, prefilled }) => {
  const { form, formContent, isLoading } = inputProps
  const [isEmailFocused, setIsEmailFocused] = React.useState(false)
  const showLoader = Boolean(isLoading && !isEmailFocused)
  const businessEmailAddressRef = React.useRef<HTMLInputElement>(null)
  const reset = () => {
    inputProps.resetForm()
    clearStoredUser()
  }

  return (
    <>
      <FormRow>
        <BusinessEmailInput
          form={form}
          prefilled={prefilled}
          reset={reset}
          formContent={formContent}
          isLoading={showLoader}
          onBlur={(event) => {
            inputProps.forceSetDebouncedEmail(event.target.value)
            setIsEmailFocused(false)
          }}
          onFocus={() => {
            setIsEmailFocused(true)
          }}
          ref={businessEmailAddressRef}
        />
      </FormRow>
      {showLoader ? <FormLoader /> : null}
    </>
  )
}

export const AllFields = ({ inputProps }) => (
  <>
    <EmailOnly inputProps={inputProps} prefilled={false} />
    <FormRow>
      <FirstNameInput {...inputProps} />
      <LastNameInput {...inputProps} />
    </FormRow>

    <>
      <FormRow>
        <CompanyNameInput {...inputProps} />
        <JobTitleInput {...inputProps} />
      </FormRow>

      <FormRow>
        <CountryInput {...inputProps} />
      </FormRow>
    </>
  </>
)

import Grid from '@material-ui/core/Grid'
import React from 'react'

import Facebook from '../../images/socialFacebook.svg'
import LinkedIn from '../../images/socialLinkedIn.svg'
import Twitter from '../../images/socialTwitter.svg'

const ShareLinks = () => {
  const url =
    typeof window !== 'undefined'
      ? encodeURIComponent(window.location.href)
      : ''

  return (
    <Grid container item justifyContent="flex-start" style={{ width: 'auto' }}>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="Share on LinkedIn" src={LinkedIn} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${url}`}
        rel="noopener noreferrer"
        style={{ marginLeft: '2rem' }}
        target="_blank"
      >
        <img alt="Share on Twitter" src={Twitter} />
      </a>
      <a
        href={`https://www.facebook.com/sharer.php?u=${url}`}
        rel="noopener noreferrer"
        style={{ marginLeft: '2rem' }}
        target="_blank"
      >
        <img alt="Share on Facebook" src={Facebook} />
      </a>
    </Grid>
  )
}

export default ShareLinks

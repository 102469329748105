import { useEffect, useState } from "react";
import { useInputEvent } from "./useInputEvent";

type CodeListener = [boolean, () => void]

export const useSecretCode = (secretCode): CodeListener => {
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(false);
  const key = useInputEvent();
  const reset = () => setSuccess(false)

  useEffect(() => {
    // ignore keyup
    if (key == null) return;

    // reset if invalid key
    if (key !== secretCode[count]) {
      setCount(0);
      return;
    }

    // valid key
    setCount((state) => state + 1);

    // code complete
    if (count + 1 === secretCode.length) {
      setSuccess(true);
    }
  }, [key]);

  return [success, reset];
};

const konamiCode = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "KeyB",
  "KeyA",
];

export const useKonamiCode = (): CodeListener => {
  const noice = useSecretCode(konamiCode);
  return noice;
};

export const useDevCode = (): CodeListener => {
  const [success, reset] = useSecretCode(['KeyQ', 'KeyQ', "KeyQ", "KeyQ", "KeyQ"]);
  return [success, reset];
};
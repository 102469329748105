import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const CardContainer = styled.div`
  border: 4px solid;
  margin-top: 24px;
  border-radius: 16px;
  border-color: ${rspTheme.palette.secondary.border};
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
  padding: 96px 16px;

  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;

  .imageContainer {
    display: flex;
    align-items: stretch;

    .gatsby-image-wrapper {
      border-radius: 8px;
    }
  }

  .imageContainer,
  .contentContainer {
    width: calc(50% - 24px);
    min-width: 320px;
  }

  .headline {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 20px;
  }

  .body {
    margin-bottom: 20px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    display: none;
    padding: 48px 16px;

    .imageContainer,
    .contentContainer {
      width: 100%;
    }

    .imageContainer {
      margin-bottom: 8px;
    }

    .headline {
      color: ${rspTheme.palette.secondary.dark};
      font-size: 2.8rem;
      margin-bottom: 32px;
    }

    .body {
      font-size: 1.6rem;
      line-height: 1.5;
      margin-bottom: 32px;
    }
  }
`

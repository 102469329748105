import { useEffect, useRef, useState } from 'react'

export const useDebouncedValue = <T,>(value: T, time = 1000) => {
  const [isPending, setIsPending] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState(value)

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    setIsPending(true)

    timeout.current = setTimeout(() => {
      setIsPending(false)
      setDebouncedValue(value)
    }, time)

    return () => {
      if (timeout.current) {
        setIsPending(false)
        clearTimeout(timeout.current)
      }
    }
  }, [value])

  const forceSetDebouncedValue = (newValue: T) => {
    if (timeout.current) {
      setIsPending(false)
      clearTimeout(timeout.current)
    }

    setDebouncedValue(newValue)

    setTimeout(() => {
      setIsPending(false)
    }, 1)
  }

  return [debouncedValue, forceSetDebouncedValue, { isPending }] as const
}

import React from 'react'
import { useStore } from '@nanostores/react'
import { useDevCode } from '../../hooks/useSecretCode'
import {
  userFormExpanded,
  formPreSubmissionStore,
  formSubmissionStore,
  storedClearbit,
  storedUser,
} from './store'
import { formatJson } from '../../utils/json'

const CodeBlock = ({ label, children }) => {
  return (
    <>
      <h5 style={{ marginBottom: '0.5rem', padding: '0' }}>{label}</h5>
      <pre
        style={{
          display: 'block',
          maxHeight: '200px',
          marginTop: '0',
          overflow: 'scroll',
          backgroundColor: '#111',
          color: '#fff',
        }}
      >
        {children}
      </pre>
    </>
  )
}
const debuggerStyle: React.CSSProperties = {
  display: 'block',
  position: 'fixed',
  top: 100,
  left: 0,
  color: '#000',
  backgroundColor: '#FC0',
  width: '50vw',
  height: 'auto',
  padding: '1rem 2rem 2rem',
  zIndex: 9999,
}
const closeButtonStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  color: '#FC0',
  backgroundColor: '#000',
  borderColor: '#db0',
  padding: '1rem 2rem',
}

export const FormDebugger = ({ data }) => {
  const [show, reset] = useDevCode()
  const formExpanded = useStore(userFormExpanded)
  const storedUserData = useStore(storedUser)
  const clearbitData = useStore(storedClearbit)
  const submission = useStore(formSubmissionStore)
  const formik = useStore(formPreSubmissionStore)

  if (!show) return null
  return (
    <div style={debuggerStyle}>
      <button style={closeButtonStyle} onClick={reset}>
        ×
      </button>
      <p>Form Expanded: {formExpanded ? 'yep' : 'nope'}</p>
      <p>isLoading: {data.inputProps.isLoading ? 'yep' : 'nope'}</p>
      <CodeBlock label="formfields">{formatJson(data.inputProps.form.values)}</CodeBlock>
      <CodeBlock label="Stored User Data">{formatJson(storedUserData)}</CodeBlock>
      <CodeBlock label="Clearbit Data">{formatJson(clearbitData)}</CodeBlock>
      <CodeBlock label="Formik Data">{formatJson(formik)}</CodeBlock>
      <CodeBlock label="Submission Data">{formatJson(submission)}</CodeBlock>
    </div>
  )
}

import { FormikConfig } from 'formik'
import { AnySchema, object, string } from 'yup'

import { cleanString } from '../helpers'

export type FormContent = Exclude<
  Exclude<Queries.ContactUsPageQuery['contentstackPageContactUs'], null>['form'],
  null
>[number]

export type ErrorMessages = Partial<
  Pick<Exclude<FormContent, null>, 'email_error_message' | 'required_error_message' | 'numbers_only_error_message'>
>

const validationSchemaEmailOnlyForm = <T extends Record<string, unknown>>(
  initialValues: T,
  errors: ErrorMessages,
  formId: string,
): FormikConfig<T>['validationSchema'] => {
  const emailError = cleanString(errors.email_error_message?.[0]?.copy ?? '')
  const requiredError = cleanString(errors.required_error_message?.[0]?.copy ?? '')

  const checkKey = (key: string, schema: AnySchema) =>
    Object.prototype.hasOwnProperty.call(initialValues, key) ? { [key]: schema } : {}

  return object().shape({
    ...checkKey('businessEmail', string().required(requiredError).email(emailError)),
    ...checkKey('email', string().required(requiredError).email(emailError)),
  })
}

export default validationSchemaEmailOnlyForm

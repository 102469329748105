import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'html-react-parser'
import React, { FC } from 'react'

import DownloadResourceForm from '../DownloadResourceForm'
import PromoCardHorizontal from '../UI/PromoCardHorizontal'
import Wrapper from '../UI/Wrapper'
import { DownloadableResourceSection, PromoCardWrapper } from './styles'
import { GoogleRecaptchaProvider } from '../../utils/recaptcha'

interface DownloadableResourceBodyProps {
  productPage?: any
  whatsInsideBody: string
  whatsInsideHeadline: string
  ctaText: string
  resourceUrl?: string
  resourceDownloadUrl?: string
  formContent: any
  manualDownloadPromptText?: string
}

const DownloadableResourceBody: FC<DownloadableResourceBodyProps> = ({
  productPage,
  whatsInsideBody,
  whatsInsideHeadline,
  ctaText,
  resourceUrl = '/',
  resourceDownloadUrl = '',
  formContent,
  manualDownloadPromptText,
}) => (
  <DownloadableResourceSection>
    <Wrapper>
      <Grid className="twoUp" container>
        <div className="whatsInsideContainer">
          <Typography className="headline" component="h2" variant="h3">
            {whatsInsideHeadline}
          </Typography>
          {whatsInsideBody ? parse(whatsInsideBody) : null}
        </div>

        <div className="formContainer">
          <GoogleRecaptchaProvider>
            <DownloadResourceForm
              formContent={formContent}
              manualDownloadPromptText={manualDownloadPromptText}
              resourceDownloadUrl={resourceDownloadUrl}
              resourceUrl={resourceUrl}
            />
          </GoogleRecaptchaProvider>
        </div>
      </Grid>
    </Wrapper>
    {productPage ? (
      <PromoCardWrapper>
        <Wrapper>
          <PromoCardHorizontal
            body={
              productPage.product?.short_description ||
              productPage.program?.[0]?.short_description
            }
            featureImage={
              productPage.product?.feature_image ||
              productPage.program?.[0]?.feature_image
            }
            headline={
              productPage.product?.headline ||
              productPage.program?.[0]?.headline
            }
            linkTarget={productPage.url}
            linkText={ctaText}
          />
        </Wrapper>
      </PromoCardWrapper>
    ) : null}
  </DownloadableResourceSection>
)

export default DownloadableResourceBody

import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import LinkCTA from '../LinkCTA'
import { CardContainer } from './styles'

interface PromoCardHorizontalProps {
  headline?: string
  body?: string
  cta?: any
  featureImage?: any
  linkText?: string
  linkTarget?: string
}

const PromoCardHorizontal = (props: PromoCardHorizontalProps) => {
  const {
    featureImage,
    headline = '',
    body = '',
    linkText = '',
    linkTarget = '/',
  } = props
  const image = getImage(featureImage?.imgixImage)

  return (
    <CardContainer>
      <div className="imageContainer">
        {image ? (
          <GatsbyImage alt={featureImage?.description || ''} image={image} />
        ) : null}
      </div>
      <div className="contentContainer">
        <Typography className="headline" component="h2" variant="h3">
          {headline}
        </Typography>
        <Typography className="body" component="p" variant="body1">
          {body}
        </Typography>
        <LinkCTA arrow directUrl={linkTarget} variant="primary">
          {linkText}
        </LinkCTA>
      </div>
    </CardContainer>
  )
}

export default PromoCardHorizontal

import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import DownloadableResourceBody from '../components/DownloadableResourceBody'
import Layout from '../components/Layout'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import ResourceHero from '../components/ResourceHero'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import { useTrackResourcePage } from '../utils/dataLayer'

const DownloadableResourcePage = ({
  data,
  pageContext,
}: PageProps<
  Queries.downloadableResourcePageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageResource
  const formContent = data?.formContent

  const parentUrl = data?.parentUrl
  const breadcrumbLinks = [
    { text: parentUrl?.title || '', target: parentUrl?.pageUrl || '/' },
    { text: pageData?.title || '' },
  ]

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes || [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards = data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const allProductPages = data?.productCards?.nodes?.[0]
    ? data?.productCards?.nodes
    : data?.programCards?.nodes
  const productPage =
    allProductPages?.[Math.floor(Math.random() * allProductPages.length)]
  const solutionsContent = pageData?.featured_solutions?.[0]
    ? {
        ...pageData?.featured_solutions?.[0],
        cta_text: data?.solutionsCarousel?.nodes?.[0]?.cta_text,
      }
    : data?.solutionsCarousel?.nodes?.[0]

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  useTrackResourcePage(data)

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <ResourceHero
        author={pageData?.resource?.[0]?.author?.[0]?.name}
        featureImage={pageData?.resource?.[0]?.feature_image}
        headline={pageData?.resource?.[0]?.headline || ''}
        locale={pageData?.locale}
        pubDate={pageData?.resource?.[0]?.publication_date}
        resourceType={pageData?.resource?.[0]?.resource_type?.[0]?.title}
        topicTag={pageData?.resource?.[0]?.resource_topic?.[0]?.headline}
      />
      <DownloadableResourceBody
        ctaText={
          data?.allContentstackResourcesWebsite?.nodes?.[0]?.promo_card_cta_text
        }
        formContent={formContent}
        manualDownloadPromptText={
          data?.allContentstackResourcesWebsite?.nodes?.[0]
            ?.manual_download_prompt_text
        }
        productPage={productPage}
        resourceDownloadUrl={pageData?.resource?.[0]?.download_url}
        resourceUrl={pageData?.resource?.[0]?.download?.url}
        whatsInsideBody={pageData?.resource?.[0]?.what_s_inside}
        whatsInsideHeadline={
          data?.allContentstackResourcesWebsite?.nodes?.[0]
            ?.what_s_inside_headline
        }
      />

      {pageData?.resource_grid?.[0] ? (
        <RelatedResources
          content={{
            related_resources: resourcesByFilter,
            ...pageData?.resource_grid?.[0],
          }}
        />
      ) : null}
      <RelatedSolutions relatedSolutions={solutionsContent} />
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.downloadableResourcePageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageResource?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageResource?.title}
      parent={data?.parentUrl}
    />
  </>
)

export default DownloadableResourcePage

export const query = graphql`
  query downloadableResourcePage(
    $pageId: String
    $productId: String
    $filterId: String
    $locale: String
  ) {
    contentstackPageResource(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      resource {
        ... on Contentstack_topic_resource {
          is_gated
          id
          headline
          what_s_inside
          download {
            url
          }
          download_url
          resource_topic {
            headline
          }
          resource_type {
            title
          }
          author {
            name
          }
          publication_date
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 1140, imgixParams: { q: 65 })
            }
          }
        }
      }
      resource_grid {
        ...RelatedResourcesSection
      }
      featured_solutions {
        ...FeaturedProgramsSection
      }
    }

    formContent: contentstackSectionLeadGenForm(
      variant: { eq: "Inline" }
      publish_details: { locale: { eq: $locale } }
    ) {
      ...LeadGenForm
    }

    productCards: allContentstackPageProduct(
      filter: { product: { elemMatch: { id: { eq: $productId } } } }
    ) {
      nodes {
        id
        url
        product {
          id
          headline
          short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 485, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    programCards: allContentstackPageSalesTrainingProgram(
      filter: { program: { elemMatch: { id: { eq: $productId } } } }
    ) {
      nodes {
        id
        url
        program {
          id
          headline
          short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 485, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    solutionsCarousel: allContentstackSectionSolutionsCardCarousel(
      filter: {
        title: { eq: "Solutions Card Carousel - Global" }
        publish_details: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        ...FeaturedProgramsSection
      }
    }

    parentUrl: contentstackPageResourcesOverview(
      publish_details: { locale: { eq: $locale } }
    ) {
      title
      pageUrl: url
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        promo_card_cta_text
        what_s_inside_headline
        manual_download_prompt_text
      }
    }
  }
`

import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const FormWrapper = styled.div`
  border-radius: 10px;
  background-color: ${rspTheme.palette.primary.dark};
  color: #ffffff;
  padding: 32px 28px;
  width: 100%;
  min-width: 340px;
  position: relative;

  .formSubhead {
    color: #ffffff;
    margin-bottom: 32px;
    max-width: 24ch;
  }

  .smallText {
    margin: 0 0 24px;

    a {
      color: ${rspTheme.palette.primary.background};
      font-size: ${rspTheme.typography.subtitle1.fontSize};
      line-height: ${rspTheme.typography.subtitle1.lineHeight};
      text-decoration-color: transparent;
      transition: 0.3s ease-in-out;

      &:hover,
      &:focus-visible {
        text-decoration-color: inherit;
      }
    }
  }

  .downloadForm {
    .errorText {
      color: #f44336;
      font-size: 1.3rem;
      line-height: 1.5;
      font-weight: 400;
      margin-left: 3.2rem;
      margin-top: 0.8rem;
    }

    .gdpr-consent {
      margin-bottom: 24px;

      .MuiFormControlLabel-root {
        align-items: flex-start;
      }
    }

    .MuiFilledInput-adornedEnd {
      padding-right: 0;
    }

    .textField {
      width: 100%;
      min-width: 200px;
      margin-bottom: 20px;

      &.textFieldHalf {
        width: 48%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &.hasFieldsBelow {
        margin-bottom: 0;
      }

      &.customBackgroundInput {
        .MuiInputBase-input {
          background-color: ${rspTheme.palette.primary.dark};
          color: #ffffff;
          border: 1px solid white;
          cursor: default;
        }
        .MuiFilledInput-root {
          background-color: transparent;
        }
        .MuiFormLabel-root {
          color: #ffffff;
        }
      }

      .MuiInputBase-input {
        background-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 24px;
      }

      .MuiFilledInput-input {
        padding: 24px 12px 8px;
      }

      .MuiFilledInput-underline::after {
        border-bottom-color: transparent;
      }

      .MuiFilledInput-underline::before {
        border-bottom-color: transparent;
      }

      .MuiFormControl-root {
        position: relative;
      }

      .MuiFormLabel-root {
        color: ${rspTheme.palette.primary.main};
      }

      .MuiFormHelperText-root {
        position: absolute;
        top: calc(100% - 24px);
        letter-spacing: -0.6px;
      }

      .MuiFormHelperText-contained {
        margin-left: 0;
        width: 100%;
      }

      .MuiSelect-iconFilled {
        top: 16px;
      }

      .MuiFilledInput-multiline {
        min-height: 148px;
        padding: 0;

        textarea {
          width: 100%;
          min-height: 148px;
          height: unset;
        }
      }

      .clearEmailIcon {
        position: absolute;
        right: 0px;
        color: lightGray;
        cursor: pointer;
        transform: translate(-50%, -50%);
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 0;
  }
`
export const FormLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background-color: rgba(55, 55, 55, 0.5);
  top: 0;
  left: 0;,
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:10px;

  &:before {
    content: "";
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position:absolute;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
  }`
